import React from "react";

import instructions from "../../assets/instructions.png";
import Container from "../../components/Container/container";
import "./quickstart.scss";

const questions = [
	{
		q: `What are Tearribles?`,
		a: `Tearribles are interactive toys, designed to provide your dog with mental stimulation, and satisfaction for dismembering their "prey."`,
	},
	{
		q: null,
		a: (
			<div className="product-bullets__video">
				<div className="product-bullets__video-wrapper">
					<iframe
						src="https://www.youtube.com/embed/V4MMPrFsAhE?modestbranding=1"
						frameBorder="0"
						allow="encrypted-media;"
						allowFullScreen
					></iframe>
				</div>
			</div>
		),
	},
	{
		q: `How should we play with Tearribles?`,
		a: `Dogs love playing, and each dog has their own favorite game.  But they all have one thing in common- they LOVE playtime the most when their favorite human plays with them.  Tearribles allow you and your dog to spend hours upon hours of quality play time together.  We've put some fun games below for you.`,
	},
	{
		q: `Should I let my dog chew on it?`,
		a: `No. Think of your Tearrible like you think of a tennis ball- it's for interactive play between you and your dog, not chewing. Tearribles are made of plush and lined with mesh, which will provide your dog with enough resistance to tear the limbs off without destroying the toy. They are not a toy you would give to your dog to chew or gnaw on. Using your Tearrible as intended will increase its life span. Using your Tearrible as a chew toy will significantly decrease its life span. And then your dog will be sad.`,
	},
	{
		q: `How do you install the removable squeaker?`,
		a: (
			<>
				<p>
					If you purchased a toy that has a removable squeaker, please
					follow these instructions:
				</p>
				<img
					src={instructions}
					alt="Instructions for installing the removable squeaker"
					className="quickstart__img"
				/>
				<h5 className="quickstart__precautions">Precautions</h5>
				<li>Remove all packaging from the toy</li>
				<li>Do not allow dog to chew on the toy</li>
				<li>
					Do not allow the dog to swallow the squeaker or any part of
					the toy
				</li>
				<li>Put toy out of dog's reach once play time is over</li>
				<li>Discard all packaging</li>
			</>
		),
	},
	{
		q: `Reminder:`,
		a: `Remove all packaging from the toy before use, and observe toy's tag instructions. Exercise proper caution, especially if your dog has been known to eat inanimate objects.`,
	},
];

const games = [
	{
		title: `Good Ole' Tug`,
		description: `You grab the toy by the non-detachable part, and get your dog to grab the detachable limb.  Play until the limb is off.  Repeat until your buddy has successfully removed all detachable parts of the toy. Re-construct the toy and repeat. Pro tip: Make sure one of you is always holding onto a detachable part during tug.`,
	},
	{
		title: `Fetch and Tug`,
		description: `Throw the Tearrible for your dog.  Upon retrieval, play tug until one limb is off.  Throw the tearrible again. Repeat until all limbs are off. Re-construct the toy and repeat until your dog is happy and tired.`,
	},
	{
		title: `Hide and Seek`,
		description: `Make your dog sit in a different room and hide pieces of the toy all over the room.  Get excited and have your dog find them. Once Sherlock has retrieved all the pieces, put the toy back together and play fetch or tub as a reward. This is a wonderful mental stimulation game, that a lot of dogs enjoy, especially those with strong tracking skills.`,
	},
	{
		title: `Descructodog`,
		description: `Show your dog how they can get a limb off the toy by playing tug, or moving the tearrible 'victim' while dog tries to 'kill' it. Once the dog figures it out, let them pull the limbs off. Be there to put them back on, so they can enjoy continuous play. Pro-tip: hide a treat in a limb pocket before re-attaching limbs for extra rewarding play.`,
	},
	{
		title: `Dig'n'Squeak`,
		description: `If you have a dog that just loves to dig, hid the Tearrible under a blanket or pillows, and let your dog 'dig' it out. Once out, they will most likely want to either play you the song of their people (squeak, squeak...) and remove the limbs themselves, or ask you to participate. Once the limbs are off, reconstruct the toy and repeat.`,
	},
];

const renderQuestion = (questions) => {
	if (!Array.isArray(questions)) return null;

	return questions.map((question, index) => {
		return (
			<div className="quickstart__qa" key={`question__${index}`}>
				{question.q && (
					<h3 className="quickstart__question">{question.q}</h3>
				)}
				<p className="quickstart__answer">{question.a}</p>
			</div>
		);
	});
};

const renderGames = (games) => {
	if (!Array.isArray(games)) return null;

	return games.map((game, index) => {
		return (
			<div className="quickstart__game" key={`game__${index}`}>
				{game.title && (
					<h3 className="quickstart__game-title">{game.title}</h3>
				)}
				<p className="quickstart__game-description">
					{game.description}
				</p>
			</div>
		);
	});
};

const QuickStart = () => (
	<div className="quickstart">
		<Container>
			<h2>Quick Start Guide</h2>
			{/* <h4></h4> */}
			<div className="quickstart__wrapper">
				{/* <div className="quickstart__images">
					<img className="quickstart__img" src={all} alt="All Tearribles" /> */}
				{/* <img className="quickstart__img" src={all} alt="All Tearribles" />
                    <img className="quickstart__img" src={all} alt="All Tearribles" /> */}
				{/* </div> */}
				<div className="quickstart__text">
					{renderQuestion(questions)}
				</div>
			</div>
			<h2>Play Ideas</h2>
			<div className="quickstart__wrapper">
				<div className="quickstart__games">{renderGames(games)}</div>
			</div>
		</Container>
	</div>
);

export default QuickStart;
