import React from 'react'
import { Helmet } from 'react-helmet'
import QuickStart from '../components/Quickstart/quickstart'

const Quickstart = () => (<>
    <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
    <QuickStart />
</>)

export default Quickstart
